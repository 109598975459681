<template>
  <div class="order_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.TransactionList }}</div>
      <div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          stripe
          v-loading="loading"
          :header-cell-style="{ background: '#fafafa' }"
        >
          <!-- 提交时间 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Submissiontime"
            min-width="10%"
          >
          </el-table-column>
          <!-- 退款单号 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Refundnotenumber"
            min-width="10%"
          >
          </el-table-column>
          <!-- 退款金额 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.refundamount"
            min-width="10%"
          >
          </el-table-column>
          <!-- 商户订单号 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Merchantordernumber"
            min-width="10%"
          >
          </el-table-column>
          <!-- 交易金额 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Transaction_Amount"
            min-width="10%"
          >
          </el-table-column>
          <!-- 交易时间 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.TransactionTime"
            min-width="10%"
          >
          </el-table-column>
          <!-- 成功退款金额 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Successfullyrefundedamount"
            min-width="10%"
          >
          </el-table-column>
          <!-- 退款原因 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Reasonforrefund"
            min-width="10%"
          >
          </el-table-column>
          <el-table-column :label="language.Operation" min-width="15%">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="Details(scope.row)">{{
                language.Details
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="paging">
          <el-pagination
            v-loading="loading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            current-page.sync="5"
            :page-sizes="[10, 15, 20, 25, 30]"
            :page-size="limit"
            layout="sizes, prev, pager, next"
            :total="pages"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languagetype: "",
      language: "",
      tableData: [],
      loading: false,
      pages: 1,
      page: 1,
      limit: 15,
    };
  },
  created() {
    this.changelanguage();
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
  },
  methods: {
    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    //选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },
    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
    },
  },
};
</script>
<style scoped>
.order_page {
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.order_page > div {
  min-height: calc(100vh - 129px);
}
.page_title {
  font-size: 26px;
  font-weight: 600;
}
.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}
* /deep/ .el-table .cell {
  white-space: nowrap;
}
</style>
